import { ADD_MILESTONE, DELETE_MILESTONE, UPDATE_MILESTONE } from '../actions/milestones';

const milestones = (state = [], action) => {
  switch (action.type) {
    case ADD_MILESTONE:
      return [...state, action.attributes];
    case DELETE_MILESTONE:
      return state.filter((m) => m.id !== action.id);
    case UPDATE_MILESTONE:
      return state.map((milestone) => {
        if (milestone.id === action.id) {
          return { ...milestone, ...action.attributes };
        } else {
          return milestone;
        }
      });
    default:
      return state;
  }
};

export default milestones;
