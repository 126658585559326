import { ADD_DOCUMENT, DELETE_DOCUMENT } from '../actions/documents';

const documents = (state = [], action) => {
  switch (action.type) {
    case ADD_DOCUMENT:
      return [...state, action.document];
    case DELETE_DOCUMENT:
      return state.filter((d) => d.id !== action.id);
    default:
      return state;
  }
};

export default documents;
