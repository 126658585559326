import {
  ADD_MILESTONE_TEMPLATE,
  DELETE_MILESTONE_TEMPLATE,
  UPDATE_MILESTONE_TEMPLATE,
} from '../actions/milestone-templates';

const milestoneTemplates = (state = [], action) => {
  switch (action.type) {
    case ADD_MILESTONE_TEMPLATE:
      return [...state, action.attributes];
    case DELETE_MILESTONE_TEMPLATE:
      return state.filter((m) => m.id !== action.id);
    case UPDATE_MILESTONE_TEMPLATE:
      return state.map((milestoneTemplate) => {
        if (milestoneTemplate.id === action.id) {
          return { ...milestoneTemplate, ...action.attributes };
        } else {
          return milestoneTemplate;
        }
      });
    default:
      return state;
  }
};

export default milestoneTemplates;
