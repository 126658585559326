import { send } from '../socket';

export const ADD_MILESTONE_TEMPLATE_PROJECT_TEMPLATE = 'add-milestone-template-project-template';
export const DELETE_MILESTONE_TEMPLATE_PROJECT_TEMPLATE = 'delete-milestone-template-project-template';
export const DELETE_MILESTONE_TEMPLATE_PROJECT_TEMPLATE_ON_SERVER =
  'delete-milestone-template-project-template-on-server';
export const PERSISTED_MILESTONE_TEMPLATE_PROJECT_TEMPLATE = 'persisted-milestone-template-project-template';

export const createMilestoneTemplateProjectTemplate = (attributes) => {
  send({ type: PERSISTED_MILESTONE_TEMPLATE_PROJECT_TEMPLATE, attributes });
};
export const deleteMilestoneTemplateProjectTemplate = (id) => {
  send({ type: DELETE_MILESTONE_TEMPLATE_PROJECT_TEMPLATE_ON_SERVER, id });
};
