import {
  ADD_MILESTONE_TEMPLATE_PROJECT_TEMPLATE,
  DELETE_MILESTONE_TEMPLATE_PROJECT_TEMPLATE,
} from '../actions/milestone-template-project-template';

const milestoneTemplateProjectTemplates = (state = [], action) => {
  switch (action.type) {
    case ADD_MILESTONE_TEMPLATE_PROJECT_TEMPLATE:
      return [...state, action.attributes];
    case DELETE_MILESTONE_TEMPLATE_PROJECT_TEMPLATE:
      return state.filter((m) => m.id !== action.id);
    default:
      return state;
  }
};

export default milestoneTemplateProjectTemplates;
