import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import App from '../components/app';

const mapStateToProps = (state) => {
  return {
    authToken: state.settings.authToken,
    currentUser: state.currentUser,
    status: state.settings.status,
    roles: state.roles,
  };
};

export default withRouter(connect(mapStateToProps)(App));
