import { ADD_INVOICED_DAYS, DELETE_INVOICED_DAY, UPDATE_INVOICED_DAY } from '../actions/invoiced-days';

const invoicedDays = (state = [], action) => {
  switch (action.type) {
    case ADD_INVOICED_DAYS:
      return [...state, action.attributes];
    case DELETE_INVOICED_DAY:
      return state.filter((s) => s.id !== action.id);
    case UPDATE_INVOICED_DAY:
      return state.map((invoicedDay) => {
        if (invoicedDay.id === action.id) {
          return { ...invoicedDay, ...action.attributes };
        } else {
          return invoicedDay;
        }
      });
    default:
      return state;
  }
};

export default invoicedDays;
