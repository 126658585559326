import { ADD_PROJECT, DELETE_PROJECT, UPDATE_PROJECT } from '../actions/projects';

const projects = (state = [], action) => {
  switch (action.type) {
    case ADD_PROJECT:
      return [...state, action.attributes];
    case DELETE_PROJECT:
      return state.filter((p) => p.id !== action.id);
    case UPDATE_PROJECT:
      return state.map((p) => (p.id === action.id ? { ...p, ...action.attributes } : p));
    default:
      return state;
  }
};

export default projects;
