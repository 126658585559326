import { send } from '../socket';

export const ADD_DOCUMENT = 'add-document';
export const DELETE_DOCUMENT = 'delete-document';
export const DELETE_DOCUMENT_ON_SERVER = 'delete-document-on-server';
export const PERSISTED_DOCUMENT = 'persisted-document';

export const createDocument = (attributes) => {
  send({ type: PERSISTED_DOCUMENT, attributes });
};

export const deleteDocument = (id) => {
  send({ type: DELETE_DOCUMENT_ON_SERVER, id });
};
