import { send } from '../socket';

export const ADD_CUSTOMER = 'add-customer';
export const DELETE_CUSTOMER = 'delete-customer';
export const DELETE_CUSTOMER_ON_SERVER = 'delete-customer-on-server';
export const PERSISTED_CUSTOMER = 'persisted-customer';
export const UPDATE_CUSTOMER = 'update-customer';
export const UPDATE_CUSTOMER_ON_SERVER = 'update-customer-on-server';

export const createCustomer = (attributes) => {
  send({ type: PERSISTED_CUSTOMER, attributes });
};

export const customerChange = (id, attributes) => {
  send({ type: UPDATE_CUSTOMER_ON_SERVER, id, attributes });
};
