import { ADD_PROJECT_TEMPLATE, DELETE_PROJECT_TEMPLATE, UPDATE_PROJECT_TEMPLATE } from '../actions/project-templates';

const projectTemplates = (state = [], action) => {
  switch (action.type) {
    case ADD_PROJECT_TEMPLATE:
      return [...state, action.attributes];
    case DELETE_PROJECT_TEMPLATE:
      return state.filter((p) => p.id !== action.id);
    case UPDATE_PROJECT_TEMPLATE:
      return state.map((projectTemplate) => {
        if (projectTemplate.id === action.id) {
          return { ...projectTemplate, ...action.attributes };
        } else {
          return projectTemplate;
        }
      });
    default:
      return state;
  }
};

export default projectTemplates;
