import {
  ADD_CUSTOMER_SCHEDULE,
  DELETE_CUSTOMER_SCHEDULE,
  UPDATE_CUSTOMER_SCHEDULE,
} from '../actions/customer-schedules';

const customerSchedules = (state = [], action) => {
  switch (action.type) {
    case ADD_CUSTOMER_SCHEDULE:
      return [...state, action.attributes];
    case DELETE_CUSTOMER_SCHEDULE:
      return state.filter((s) => s.id !== action.id);
    case UPDATE_CUSTOMER_SCHEDULE:
      return state.map((customerSchedule) => {
        if (customerSchedule.id === action.id) {
          return { ...customerSchedule, ...action.attributes };
        } else {
          return customerSchedule;
        }
      });
    default:
      return state;
  }
};

export default customerSchedules;
