import React, { useState } from 'react';
import isHotkey from 'is-hotkey';

import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/pro-regular-svg-icons';
import {
  faBriefcase,
  faBuilding,
  faGem,
  faSignOutAlt,
  faUpload,
  faUserLock,
  faUsers,
} from '@fortawesome/pro-solid-svg-icons';

import './navbar.css';

const isEnter = isHotkey('enter');

const Navbar = (props) => {
  const { currentUser, roles, status } = props;
  const [open, setOpen] = useState(false);
  const { t } = useTranslation('navbar');

  const signOut = () => {
    document.cookie =
      document.cookie.split(';').find((c) => c.startsWith('_gk_session=')) + ';expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    document.forms.logoutForm.submit();
  };

  const isConsultantActive = (match, location) => {
    if (match.isExact) {
      return true;
    } else {
      return location.pathname.includes('/consultants');
    }
  };

  return (
    <nav className={`navbar sticky-top navbar-expand-lg navbar-dark bg-${status === 'OK' ? 'dark' : 'danger'}`}>
      <Link to="/" className="navbar-brand">
        <FontAwesomeIcon icon={faCalendarAlt} /> Planner
      </Link>
      <button className="navbar-toggler" type="button" data-target="#navbarNav" onClick={() => setOpen(!open)}>
        <span className="navbar-toggler-icon"></span>
      </button>
      <div
        className={`navbar-collapse${open ? ' navbar-collapse--show bg-dark' : ''}`}
        id="navbarNav"
        onClick={() => setOpen(!open)}
        onKeyUp={(e) => isEnter(e) && setOpen(!open)}
        role="button"
        tabIndex="-1"
      >
        <ul className="navbar-nav mr-auto">
          <li className="nav-item">
            <NavLink isActive={isConsultantActive} to="/" className="nav-link" activeClassName="active">
              <FontAwesomeIcon icon={faUsers} /> {t('consultants')}
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/customers" className="nav-link" activeClassName="active">
              <FontAwesomeIcon icon={faBuilding} /> {t('customers')}
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/projects" className="nav-link" activeClassName="active">
              <FontAwesomeIcon icon={faBriefcase} /> {t('projects')}
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/milestone-templates" className="nav-link" activeClassName="active">
              <FontAwesomeIcon icon={faGem} /> {t('milestoneTemplates')}
            </NavLink>
          </li>
          {currentUser.securityLevel >= roles.admin.securityLevel ? (
            <React.Fragment>
              <li className="nav-item">
                <NavLink to="/imports" className="nav-link" activeClassName="active">
                  <FontAwesomeIcon icon={faUpload} /> {t('imports')}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/users" className="nav-link" activeClassName="active">
                  <FontAwesomeIcon icon={faUserLock} /> {t('users')}
                </NavLink>
              </li>
            </React.Fragment>
          ) : null}
          <li className="nav-item d-lg-none">
            <a href="#logout" onClick={signOut} className="nav-link text-white">
              <FontAwesomeIcon icon={faSignOutAlt} /> {t('logOut')}
              <form action={`${process.env.REACT_APP_GATEKEEPER_URL}/sessions`} method="DELETE"></form>
            </a>
          </li>
        </ul>

        <ul className="navbar-nav d-none d-lg-flex">
          <li className="nav-item d-flex align-items-center">
            <img
              src={`${process.env.REACT_APP_GATEKEEPER_URL}/avatars/${currentUser.md5Hash}`}
              alt={currentUser.firstName}
              className="rounded-circle"
              width="30px"
              height="30px"
            />
            <a href="#logout" onClick={signOut} className="nav-link text-white">
              <FontAwesomeIcon icon={faSignOutAlt} /> {t('logOut')}
              <form id="logoutForm" action={`${process.env.REACT_APP_GATEKEEPER_URL}/session`} method="POST">
                <input type="hidden" name="_method" value="delete" />
              </form>
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
