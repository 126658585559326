import { send } from '../socket';

export const ADD_MILESTONE = 'add-milestone';
export const DELETE_MILESTONE = 'delete-milestone';
export const DELETE_MILESTONE_ON_SERVER = 'delete-milestone-on-server';
export const PERSISTED_MILESTONE = 'persisted-milestone';
export const UPDATE_MILESTONE = 'update-milestone';
export const UPDATE_MILESTONE_ON_SERVER = 'update-milestone-on-server';

export const createMilestone = (attributes) => {
  send({ type: PERSISTED_MILESTONE, attributes });
};

export const deleteMilestone = (id) => {
  send({ type: DELETE_MILESTONE_ON_SERVER, id });
};

export const milestoneChange = (id, attributes) => {
  send({ type: UPDATE_MILESTONE_ON_SERVER, id, attributes });
};
