import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import store, { history } from './store';

import { createSocket } from './socket';

import App from './containers/app';
import { unregister } from './registerServiceWorker';

import 'moment/locale/nl';

import './i18n';
import './index.scss';

const target = document.querySelector('#root');
createSocket(store);

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  target,
);
unregister();
