import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { fetchInitialData, fetchInitialDataFailure, fetchInitialDataSuccess } from '../actions/initial-data';

import InitialData from '../components/initial-data';

const mapStateToProps = (state) => {
  return {
    authToken: state.settings.authToken,
    isLoading: state.initialData.isFetching,
    errors: state.initialData.errors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchInitialData,
      fetchInitialDataFailure,
      fetchInitialDataSuccess,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(InitialData);
