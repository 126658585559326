import { updateStatus } from './settings';

export const FETCH_INITIAL_DATA = 'fetch-initial-data';
export const FETCH_INITIAL_DATA_FAILURE = 'fetch-initial-data-failure';
export const FETCH_INITIAL_DATA_SUCCESS = 'fetch-initial-data-success';

export const fetchInitialData = () => ({ type: FETCH_INITIAL_DATA });
export const fetchInitialDataFailure = (errors) => {
  return (dispatch) => {
    dispatch({ type: FETCH_INITIAL_DATA_FAILURE, errors });
    dispatch(updateStatus('OK', errors));
  };
};
export const fetchInitialDataSuccess = (data) => ({ type: FETCH_INITIAL_DATA_SUCCESS, data });
