import { send } from '../socket';

export const ADD_USER = 'add-user';
export const DELETE_USER = 'delete-user';
export const UPDATE_USER = 'update-user';
export const UPDATE_USER_ON_SERVER = 'update-user-on-server';

export const updateUserOnServer = (id, attributes) => {
  send({ type: UPDATE_USER_ON_SERVER, id, attributes });
};
