import React, { Component } from 'react';
import i18n from 'i18next';
import moment from 'moment';

import './initial-data.css';

class InitialData extends Component {
  componentDidMount() {
    const { authToken, fetchInitialData, fetchInitialDataFailure, fetchInitialDataSuccess } = this.props;

    const url = `${process.env.REACT_APP_BACKEND_URL}/initial-state`;
    const options = {
      headers: {
        Authorization: authToken,
      },
    };

    fetchInitialData();
    fetch(url, options)
      .then((r) => {
        if (r.status !== 200) {
          throw Error(r.statusText);
        }
        return r.json();
      })
      .then((json) => {
        fetchInitialDataSuccess(json);
        i18n.changeLanguage(json.currentUser.locale);
        moment.locale(json.currentUser.locale);
      })
      .catch((error) => {
        fetchInitialDataFailure(error.message);
      });
  }

  render() {
    const { isLoading, errors } = this.props;

    if (isLoading) {
      return <div className="loading"></div>;
    } else {
      return <div className="console">{errors}</div>;
    }
  }
}

export default InitialData;
