import { send } from '../socket';

export const ADD_SCHEDULE = 'add-schedule';
export const ASSIGN_SCHEDULES = 'assign-schedules';
export const DELETE_SCHEDULE = 'delete-schedule';
export const PERSISTED_SCHEDULE = 'persisted-schedule';
export const UPDATE_SCHEDULE = 'update-schedule';
export const UPDATE_SCHEDULE_ON_SERVER = 'update-schedule-on-server';

export const assignSchedules = (attributes) => {
  send({ type: ASSIGN_SCHEDULES, attributes });
};

export const createSchedule = (attributes) => {
  send({ type: PERSISTED_SCHEDULE, attributes });
};

export const scheduleChange = (id, attributes) => {
  send({ type: UPDATE_SCHEDULE_ON_SERVER, id, attributes });
};
