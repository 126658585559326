const roles = (state = {}, action) => {
  switch (action.type) {
    case 'fetch-initial-data-success': {
      const roles = {};
      action.data.roles.forEach((role) => (roles[role.name.toLowerCase()] = role));
      return roles;
    }
    default:
      return state;
  }
};

export default roles;
