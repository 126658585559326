import { send } from '../socket';

export const ADD_CUSTOMER_SCHEDULE = 'add-customer-schedule';
export const ASSIGN_CUSTOMER_SCHEDULES = 'assign-customer-schedules';
export const DELETE_CUSTOMER_SCHEDULE = 'delete-customer-schedule';
export const PERSISTED_CUSTOMER_SCHEDULE = 'persisted-customer-schedule';
export const UPDATE_CUSTOMER_SCHEDULE = 'update-customer-schedule';
export const UPDATE_CUSTOMER_SCHEDULE_ON_SERVER = 'update-customer-schedule-on-server';

export const createCustomerSchedule = (attributes) => {
  send({ type: PERSISTED_CUSTOMER_SCHEDULE, attributes });
};

export const customerScheduleChange = (id, attributes) => {
  send({ type: UPDATE_CUSTOMER_SCHEDULE_ON_SERVER, id, attributes });
};
