import { lazy } from 'react';

const ConsultantContainer = lazy(() => import('./containers/consultant'));
const ConsultantsContainer = lazy(() => import('./containers/consultants'));
const CustomerContainer = lazy(() => import('./containers/customer'));
const CustomersContainer = lazy(() => import('./containers/customers'));
const ImportsContainer = lazy(() => import('./containers/imports'));
const MilestoneContainer = lazy(() => import('./containers/milestone'));
const MilestoneNewContainer = lazy(() => import('./containers/milestone-new'));
const MilestoneTemplateContainer = lazy(() => import('./containers/milestone-template'));
const MilestoneTemplatesContainer = lazy(() => import('./containers/milestone-templates'));
const ProjectContainer = lazy(() => import('./containers/project'));
const ProjectsContainer = lazy(() => import('./containers/projects'));
const SettingsContainer = lazy(() => import('./containers/settings'));
const UsersContainer = lazy(() => import('./containers/users'));

const routes = [
  {
    path: '/',
    exact: true,
    component: ConsultantsContainer,
    securityLevel: 0,
  },
  {
    path: '/consultants/:id',
    exact: true,
    component: ConsultantContainer,
    securityLevel: 0,
  },
  {
    path: '/customers',
    exact: true,
    component: CustomersContainer,
    securityLevel: 0,
  },
  {
    path: '/customers/:id',
    exact: true,
    component: CustomerContainer,
    securityLevel: 0,
  },
  {
    path: '/imports',
    exact: true,
    component: ImportsContainer,
    securityLevel: 1,
  },
  {
    path: '/projects',
    exact: true,
    component: ProjectsContainer,
    securityLevel: 0,
  },
  {
    path: '/projects/:id',
    exact: true,
    component: ProjectContainer,
    securityLevel: 0,
  },
  {
    path: '/projects/:projectId/milestone-new',
    exact: true,
    component: MilestoneNewContainer,
    securityLevel: 0,
  },
  {
    path: '/projects/:projectId/milestones/:id',
    exact: true,
    component: MilestoneContainer,
    securityLevel: 0,
  },
  {
    path: '/milestone-templates',
    exact: true,
    component: MilestoneTemplatesContainer,
    securityLevel: 0,
  },
  {
    path: '/milestone-templates/:id',
    exact: true,
    component: MilestoneTemplateContainer,
    securityLevel: 0,
  },
  {
    path: '/settings',
    exact: true,
    component: SettingsContainer,
    securityLevel: 0,
  },
  {
    path: '/users',
    exact: true,
    component: UsersContainer,
    securityLevel: 1,
  },
];

export default routes;
