import { updateStatus } from './actions/settings';

class WebSocketClient {
  constructor() {
    this.url = process.env.REACT_APP_SOCKET_URL;
    this.store = null;
    this.connection = null;
  }

  bindEvents() {
    this.connection.onopen = this.onOpen;
    this.connection.onmessage = this.onMessage;
    this.connection.onclose = this.onClose;
  }

  open() {
    this.connection = new WebSocket(this.url);
    this.bindEvents();
  }

  onOpen = () => {
    const authToken = this.store.getState().settings.authToken;

    if (authToken !== '') {
      this.updateStastus('Connect to server');
      this.connection.send(authToken);
    }
  };

  onMessage = (event) => {
    this.store.dispatch(JSON.parse(event.data));
  };

  onClose = (event) => {
    if (event.code > 1000) {
      this.updateStastus(`The connection to the server has been lost. Code: ${event.code}`, 'NOT-OK');
      this.reconnect();
    } else {
      this.updateStastus(`An unknown error occurred. Code: ${event.code}`, 'NOT-OK');
    }
  };

  reconnect() {
    const that = this;

    setTimeout(() => {
      that.updateStastus('Reconnecting to server', 'NOT-OK');
      that.open(that.url);
    }, 5000);
  }

  send(action) {
    this.connection.send(JSON.stringify(action));
  }

  updateStastus(message, status = 'OK') {
    this.store.dispatch(updateStatus(status, message));
  }
}

const websocketClient = new WebSocketClient();

export const createSocket = (store) => {
  websocketClient.store = store;
  websocketClient.open();
};

export const send = (action) => {
  websocketClient.send(action);
};
