import moment from 'moment';

export const AUTH_TOKEN = 'auth-token';
export const UPDATE_STATUS = 'update-status';

export const setAuthToken = (authToken) => ({ type: AUTH_TOKEN, authToken });

export const updateStatus = (status, message) => ({
  type: UPDATE_STATUS,
  status,
  message: `[${moment().format('YYYY-MM-DD HH:mm.ssss')}] ${message}`,
});
