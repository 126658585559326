import React from 'react';

const Loading = ({ error, pastDelay, retry }) => {
  if (error) {
    return (
      <div className="console">
        Error!{' '}
        <button className="btn btn-link text-success" onClick={retry}>
          Retry
        </button>
        {error.message}
      </div>
    );
  } else if (pastDelay) {
    return <div className="loading">Loading...</div>;
  } else {
    return null;
  }
};

export default Loading;
