import { send } from '../socket';

export const ADD_MILESTONE_TEMPLATE = 'add-milestone-template';
export const COPY_MILESTONE_TEMPLATE_TO_MILESTONE = 'copy-milestone-template-to-milestone';
export const DELETE_MILESTONE_TEMPLATE = 'delete-milestone-template';
export const DELETE_MILESTONE_TEMPLATE_ON_SERVER = 'delete-milestone-template-on-server';
export const PERSISTED_MILESTONE_TEMPLATE = 'persisted-milestone-template';
export const UPDATE_MILESTONE_TEMPLATE = 'update-milestone-template';
export const UPDATE_MILESTONE_TEMPLATE_ON_SERVER = 'update-milestone-template-on-server';

export const createMilestoneTemplate = (attributes) => {
  send({ type: PERSISTED_MILESTONE_TEMPLATE, attributes });
};

export const deleteMilestoneTemplate = (id) => {
  send({ type: DELETE_MILESTONE_TEMPLATE_ON_SERVER, id });
};

export const milestoneTemplateChange = (id, attributes) => {
  send({ type: UPDATE_MILESTONE_TEMPLATE_ON_SERVER, id, attributes });
};

export const copyMilestoneTemplate = (id, projectId) => {
  send({ type: COPY_MILESTONE_TEMPLATE_TO_MILESTONE, id, projectId });
};
