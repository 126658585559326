import { AUTH_TOKEN, UPDATE_STATUS } from '../actions/settings';

const getAuthToken = () => {
  const cookie = document.cookie.split(';').find((c) => c.includes('_gk_session='));
  return cookie === undefined ? '' : cookie.trim().replace(/_gk_session=/, '');
};

const initialState = {
  authToken: getAuthToken(),
  status: 'OK',
  messages: [],
};

const settings = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_TOKEN:
      return { ...state, ...{ authToken: action.authToken } };
    case UPDATE_STATUS:
      return {
        ...state,
        ...{ status: action.status },
        messages: [...state.messages, action.message],
      };
    default:
      return state;
  }
};

export default settings;
