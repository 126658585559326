import React, { Component, Suspense } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import GatekeeperContainer from '../../containers/gatekeeper';
import InitialDataContainer from '../../containers/initial-data';
import Loading from '../loading';
import Navbar from '../navbar';
import routes from '../../routes';

class App extends Component {
  render() {
    const { authToken, currentUser, status, roles } = this.props;

    if (authToken === '') {
      return <Route component={GatekeeperContainer} />;
    } else if (currentUser === null) {
      return <InitialDataContainer />;
    } else {
      return (
        <React.Fragment>
          <Navbar currentUser={currentUser} status={status} roles={roles} />
          <Suspense fallback={<Loading />}>
            {routes.map((r, i) => (currentUser.securityLevel >= r.securityLevel ? <Route key={i} {...r} /> : null))}
          </Suspense>
        </React.Fragment>
      );
    }
  }
}

App.propTypes = {
  authToken: PropTypes.string.isRequired,
  currentUser: PropTypes.shape({
    id: PropTypes.number.isRequired,
    securityLevel: PropTypes.number.isRequired,
  }),
  status: PropTypes.string.isRequired,
  roles: PropTypes.object.isRequired,
};

export default withTranslation('app')(App);
