import { send } from '../socket';

export const ADD_PROJECT = 'add-project';
export const DELETE_PROJECT = 'delete-project';
export const DELETE_PROJECT_ON_SERVER = 'delete-project-on-server';
export const DISTRIBUTE_DAYS = 'distribute-days';
export const PERSISTED_PROJECT = 'persisted-project';
export const UPDATE_PROJECT = 'update-project';
export const UPDATE_PROJECT_ON_SERVER = 'update-project-on-server';

export const distributeDays = (id) => {
  send({ type: DISTRIBUTE_DAYS, id });
};

export const createProject = (attributes) => {
  send({ type: PERSISTED_PROJECT, attributes });
};

export const projectChange = (id, attributes) => {
  send({ type: UPDATE_PROJECT_ON_SERVER, id, attributes });
};

export const deleteProject = (id) => {
  send({ type: DELETE_PROJECT_ON_SERVER, id });
};
