import React from 'react';

const Gatekeeper = ({ location }) => {
  const params = {};
  location.search
    .substring(1)
    .split('&')
    .forEach((param) => {
      const keyValue = param.split('=');
      params[keyValue.shift()] = keyValue.join('=');
    });
  const authToken = params.message;

  if (authToken === undefined) {
    window.location = `${process.env.REACT_APP_GATEKEEPER_URL}/session?host=${window.location.host}&return_url=/`;
    return <div>Redirecting...</div>;
  } else {
    document.cookie = `_gk_session=${decodeURIComponent(authToken)};path=/;secure`;
    window.location = params.return_url || '/';

    return <div>Loading...</div>;
  }
};

export default Gatekeeper;
