import { send } from '../socket';

export const ADD_ACTIVITY_TEMPLATE = 'add-activity-template';
export const DELETE_ACTIVITY_TEMPLATE = 'delete-activity-template';
export const DELETE_ACTIVITY_TEMPLATE_ON_SERVER = 'delete-activity-template-on-server';
export const PERSISTED_ACTIVITY_TEMPLATE = 'persisted-activity-template';
export const UPDATE_ACTIVITY_TEMPLATE = 'update-activity-template';
export const UPDATE_ACTIVITY_TEMPLATE_ON_SERVER = 'update-activity-template-on-server';

export const addActivityTemplate = (attributes) => {
  send({ type: PERSISTED_ACTIVITY_TEMPLATE, attributes });
};

export const deleteActivityTemplate = (id) => {
  send({ type: DELETE_ACTIVITY_TEMPLATE_ON_SERVER, id });
};

export const updateActivityTemplate = (id, attributes) => {
  send({ type: UPDATE_ACTIVITY_TEMPLATE_ON_SERVER, id, attributes });
};
