import { ADD_SCHEDULE, DELETE_SCHEDULE, UPDATE_SCHEDULE } from '../actions/schedules';

const schedules = (state = [], action) => {
  switch (action.type) {
    case ADD_SCHEDULE:
      return [...state, action.attributes];
    case DELETE_SCHEDULE:
      return state.filter((s) => s.id !== action.id);
    case UPDATE_SCHEDULE:
      return state.map((schedule) => {
        if (schedule.id === action.id) {
          return { ...schedule, ...action.attributes };
        } else {
          return schedule;
        }
      });
    default:
      return state;
  }
};

export default schedules;
