import { ADD_CUSTOMER, DELETE_CUSTOMER, UPDATE_CUSTOMER } from '../actions/customers';

const customers = (state = [], action) => {
  switch (action.type) {
    case ADD_CUSTOMER:
      return [...state, action.attributes];
    case DELETE_CUSTOMER:
      return state.filter((p) => p.id !== action.id);
    case UPDATE_CUSTOMER:
      return state.map((customer) => {
        if (customer.id === action.id) {
          return { ...customer, ...action.attributes };
        } else {
          return customer;
        }
      });
    default:
      return state;
  }
};

export default customers;
