import {
  ADD_ACTIVITY_TEMPLATE,
  DELETE_ACTIVITY_TEMPLATE,
  UPDATE_ACTIVITY_TEMPLATE,
} from '../actions/activity-templates';

const activityTemplates = (state = [], action) => {
  switch (action.type) {
    case ADD_ACTIVITY_TEMPLATE:
      return [...state, action.attributes];
    case DELETE_ACTIVITY_TEMPLATE:
      return state.filter((s) => s.id !== action.id);
    case UPDATE_ACTIVITY_TEMPLATE:
      return state.map((activityTemplate) => {
        if (activityTemplate.id === action.id) {
          return { ...activityTemplate, ...action.attributes };
        } else {
          return activityTemplate;
        }
      });
    default:
      return state;
  }
};

export default activityTemplates;
