import { ADD_CONSULTANT, DELETE_CONSULTANT, UPDATE_CONSULTANT } from '../actions/consultants';

const consultants = (state = [], action) => {
  switch (action.type) {
    case ADD_CONSULTANT:
      return [...state, action.attributes];
    case DELETE_CONSULTANT:
      return state.filter((c) => c.id !== action.id);
    case UPDATE_CONSULTANT:
      return state.map((consultant) => {
        if (consultant.id === action.id) {
          return { ...consultant, ...action.attributes };
        } else {
          return consultant;
        }
      });
    default:
      return state;
  }
};

export default consultants;
