import { ADD_ACTIVITY, DELETE_ACTIVITY, UPDATE_ACTIVITY } from '../actions/activities';

const activities = (state = [], action) => {
  switch (action.type) {
    case ADD_ACTIVITY:
      return [...state, action.attributes];
    case DELETE_ACTIVITY:
      return state.filter((a) => a.id !== action.id);
    case UPDATE_ACTIVITY:
      return state.map((activity) => {
        if (activity.id === action.id) {
          return { ...activity, ...action.attributes };
        } else {
          return activity;
        }
      });
    default:
      return state;
  }
};

export default activities;
