import { FETCH_INITIAL_DATA, FETCH_INITIAL_DATA_FAILURE, FETCH_INITIAL_DATA_SUCCESS } from '../actions/initial-data';

const initailState = {
  isFetching: false,
  errors: '',
};

const initialData = (state = initailState, action) => {
  switch (action.type) {
    case FETCH_INITIAL_DATA:
      return { ...state, isFetching: true };
    case FETCH_INITIAL_DATA_FAILURE:
      return { ...state, isFetching: false, errors: action.errors };
    case FETCH_INITIAL_DATA_SUCCESS:
      return { ...state, isFetching: false };
    default:
      return state;
  }
};

export default initialData;
