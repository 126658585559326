import { send } from '../socket';

export const ADD_ACTIVITY = 'add-activity';
export const DELETE_ACTIVITY = 'delete-activity';
export const DELETE_ACTIVITY_ON_SERVER = 'delete-activity-on-server';
export const PERSISTED_ACTIVITY = 'persisted-activity';
export const UPDATE_ACTIVITY = 'update-activity';
export const UPDATE_ACTIVITY_ON_SERVER = 'update-activity-on-server';

export const activityChange = (id, attributes) => {
  send({ type: UPDATE_ACTIVITY_ON_SERVER, id, attributes });
};

export const createActivity = (attributes) => {
  send({ type: PERSISTED_ACTIVITY, attributes });
};

export const deleteActivity = (id) => {
  send({ type: DELETE_ACTIVITY_ON_SERVER, id });
};
