import { send } from '../socket';

export const ADD_INVOICED_DAYS = 'add-invoiced-days';
export const DELETE_INVOICED_DAY = 'delete-invoiced-day';
export const PERSISTED_INVOICED_DAYS = 'persisted-invoiced-days';
export const UPDATE_INVOICED_DAY = 'update-invoiced-day';

export const createInvoicedDays = (attributes) => {
  send({ type: PERSISTED_INVOICED_DAYS, attributes });
};
