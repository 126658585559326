import { send } from '../socket';

export const ADD_CONSULTANT = 'add-consultant';
export const DELETE_CONSULTANT = 'delete-consultant';
export const PERSISTED_CONSULTANT = 'persisted-consultant';
export const UPDATE_CONSULTANT = 'update-consultant';
export const UPDATE_CONSULTANT_ON_SERVER = 'update-consultant-on-server';

export const createConsultant = (attributes) => {
  send({ type: PERSISTED_CONSULTANT, attributes });
};

export const updateConsultant = (id, attributes) => {
  send({ type: UPDATE_CONSULTANT_ON_SERVER, id, attributes });
};
