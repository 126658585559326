import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import settings from './settings';
import initialData from './initial-data';

import activities from './activities';
import activityTemplates from './activity-templates';
import consultants from './consultants';
import customers from './customers';
import customerSchedules from './customer-schedules';
import currentUser from './current-user';
import documents from './documents';
import invoicedDays from './invoiced-days';
import milestones from './milestones';
import milestoneTemplates from './milestone-templates';
import milestoneTemplateProjectTemplates from './milestone-template-project-templates';
import projects from './projects';
import projectTemplates from './project-templates';
import schedules from './schedules';
import roles from './roles';
import users from './users';

export default (history) => {
  const appReducer = combineReducers({
    activities,
    activityTemplates,
    consultants,
    customers,
    customerSchedules,
    currentUser,
    documents,
    initialData,
    invoicedDays,
    milestones,
    milestoneTemplates,
    milestoneTemplateProjectTemplates,
    projects,
    projectTemplates,
    schedules,
    settings,
    roles,
    router: connectRouter(history),
    users,
  });
  return (state, action) => {
    if (action.type === 'fetch-initial-data-success') {
      const data = { ...state, ...action.data };
      return appReducer(data, action);
    }
    return appReducer(state, action);
  };
};
