import { ADD_USER, DELETE_USER, UPDATE_USER } from '../actions/users';

const users = (state = [], action) => {
  switch (action.type) {
    case ADD_USER:
      return [...state, action.attributes];
    case DELETE_USER:
      return state.filter((s) => s.id !== action.id);
    case UPDATE_USER:
      return state.map((user) => {
        if (user.id === action.id) {
          return { ...user, ...action.attributes };
        } else {
          return user;
        }
      });
    default:
      return state;
  }
};

export default users;
